@charset "utf-8";

$main : #123;
$primary : darken($main, 3%);

$family-primary: 'Poppins', sans-serif;

$black : rgba(255,255,255,.95);
$grey-dark : darken($black, 2%);
$grey-darker : darken($black, 5%);

$slider-track-background: rgba(10,10,10,.7);
$slider-output-background: transparent;
$slider-thumb-background: rgba(150,150,150,.7);
$slider-thumb-border: none;
$slider-output-width: 4.5rem;


$control-padding-vertical : 25px;
$control-padding-horizontal : 15px;
$control-radius: 0;

$control-button-size: 60px;
$control-button-margin: 1rem;
$control-button-background: rgba(0,0,0,.4);


@import "../node_modules/bulma/sass/utilities/_all.sass";
@import "../node_modules/bulma/sass/base/_all.sass";
@import "../node_modules/bulma/sass/helpers/_all.sass";
@import "../node_modules/bulma/sass/layout/_all.sass";
@import "../node_modules/bulma/sass/elements/_all.sass";


@import "../node_modules/bulma/sass/grid/_all.sass";



$navbar-background-color: rgba(5,5,5,.4);
$navbar-item-color : rgb(200, 200, 200);
$navbar-item-hover-background-color: rgba(0,0,0,.3);
$navbar-item-active-color : rgb(255,255,255);
$navbar-item-active-background-color: rgba(0,0,0,.25);
$navbar-item-hover-color: rgba(225,225,225,1);



@import "../node_modules/bulma/sass/components/navbar";

.navbar {
  backdrop-filter: blur(8px); }

@import "../node_modules/bulma/sass/components/panel";

@import "../node_modules/bulma/sass/components/level";

$modal-background-background-color : rgba(0,0,0,.5);
$modal-card-head-background-color :  rgb(0, 8, 10);
$modal-card-body-background-color : rgb(0, 8, 10);
$modal-content-width : 900px;
$modal-card-head-radius : 0;
$modal-card-foot-radius : 0e;
@import "../node_modules/bulma/sass/components/modal";



.modal pre {
  background-color: rgba(0,0,0,.3); }

.modal .button.is-primary {
  background: rgba(0,0,0,.5);
  border: 0; }

.modal.has-tabs .modal-card .tabs {
    background: $modal-card-body-background-color;
    border-bottom-color: transparent;
    margin-bottom: 0; }

.modal.has-tabs .modal-card .tabs ul li {
  transition: all 200ms ease; }

.modal.has-tabs .modal-card .tabs ul li.is-active {
  background: rgba(0,0,0,.4);
  a {
    color: #ffffff;
    border-bottom: rgba(255, 255, 255, 0.1); } }

.modal.has-tabs .modal-card .tabs ul li:not(.is-active):hover {
  background: rgba(0,0,0,.2); }

@media screen and (min-width: 1280px), print {
  .modal-card.is-large {
    width: 1500px;
    max-height: 90vh; } }


.has-height-auto {
  height: auto !important; }


@import "../node_modules/bulma/sass/components/card";

@import "../node_modules/bulma/sass/components/media";

@import "../node_modules/bulma/sass/components/tabs";



$menu-item-active-background-color : rgba(255,255,255,.05);
$menu-item-hover-color : rgba(255,255,255,.9);
$menu-item-hover-background-color : rgba(255,255,255,.035);
$menu-item-radius : 0;
$menu-label-color : rgba(255,255,255,.9);
@import "../node_modules/bulma/sass/components/menu";

aside.menu {
  background-color: rgba(0,0,0,.3);
  padding: 20px; }

$input-background-color : rgba(0,0,0,.3);
$input-disabled-background-color : $input-background-color;

$input-border-color : transparent;
$input-disabled-border-color : $input-border-color;
$input-disabled-color : $grey;

$input-hover-border-color : rgba(255,255,255,.05);
$input-focus-border-color : rgba(255,255,255,.05);
$input-focus-box-shadow-size: 0;
$input-shadow : none;

select.select:not(.is-multiple) {
  height : 52px; }

select.select {
  background: $input-background-color;
  border: 0;
  color : #ffffff;
  width : 100%;
  height : 52px;
  padding-left : 15px;
  padding-right : 15px; }

select.select option {
  background-color: $input-background-color; }

select.select:focus {
  border: 0;
  outline: 0; }


$label-weight: 400;


@import "../node_modules/bulma/sass/form/_all";




$tooltip-background-color: rgba(0,0,0,.5);
$tooltip-color: hsl(0, 0%, 100%);
@import "../node_modules/bulma-tooltip/src/sass/index";


@import "../node_modules/bulma-slider/src/sass/index";

body {
  min-height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden;
  padding-bottom: 10vh;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  background: url(https://images.unsplash.com/photo-1519018925582-6523efd59ee8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1920&q=80) no-repeat center 90% fixed; }


@media(min-width: $desktop) {
  ::-webkit-scrollbar {
    width: 0.5em;
    height: 0.5em; }

  ::-webkit-scrollbar-thumb {
    background: rgba(255,255,255,.15); }

  ::-webkit-scrollbar-track-piece {
    background: transparent; }

  ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 1); }

  ::-webkit-scrollbar-corner {
    background: transparent; } }



.content {
  h1, h2, h3, h4, h5, h6 {
    font-weight: 400; }

  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem; }

.subtitle {
  color: rgba(255,255,255,.8); }














a.is-link {
  color: #3273dc; }

a.is-link:hover {
  color: darken(#3273dc, 5%); }

.button {
  transition: all 200ms ease; }

.card {
  background-color : rgba(10,10,10,.6);
  box-shadow : none; }

p.card-header-title {
  font-weight : 400; }

.box {
  background: rgba(5, 5, 5, .5);
  backdrop-filter: blur(8px);
  border-radius: 0;
  padding : 0;
  box-shadow : none; }

.control-section {
  margin-bottom: 2rem; }

.tabs {

  @media(min-width: $desktop) {


    &::-webkit-scrollbar-thumb {
      background: rgba(255, 255, 255, 0.2); }

    &::-webkit-scrollbar-track-piece {
      background: transparent; }

    &::-webkit-scrollbar-track {
      background: rgba(255,255,255,.1); }

    &::-webkit-scrollbar-corner {
      background: transparent; } }


  ul {
    border-bottom-color: rgba(255,255,255,.1);
    li a {
      transition: all 250ms;
      padding: 15px 50px 15px 50px;
      border-bottom-color: transparent;
      color: rgba(255,255,255,.8);
      font-size: 15px; }
    li a:hover {
      border-bottom-color: rgba(255, 255, 255, 0.1);
      color: #b9b9b9; } } }

.tabs.is-secondary {
  ul {
    margin: 0; }
  ul li a {
    padding: 10px 20px 10px 20px; } }

.tabs.in-tab {
  margin-left: -2rem;
  margin-right: -2rem;
  margin-top: -1.5rem; }

.box > .columns > .column {
  padding-top: 0; }

input[type=range].slider:not([orient="vertical"]).has-output + output {
  top : 0.1rem;
  background-color: transparent; }

input[type=range].slider {
  margin-top: 0;
  margin-bottom: 0; }

label.label.has-help {
  margin-bottom: 0; }

.modal-card-title {
  font-size: 18px; }

.modal-card-head {
  border-bottom: 1px solid rgba(255,255,255,.08); }

.modal-card-foot {
  border-top: 1px solid rgba(255,255,255,.08);
  display: block; }

.modal-card-foot .button {
  height: auto;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  color: rgba(255,255,255,.8); }


button.delete, button.delete:hover, button.delete:active, button.delete:focus {
  background: none; }

.control-tile, .control-tile-container, .control-star-container {
  transition: all .15s ease-in-out; }

.control-tile-container {
  border-radius: 10px;
  overflow: hidden; }

.control-tile-container.selected {
  box-shadow: 0 0 0 1px yellow; }

.template-container {
  padding: 40px 30px 30px 30px; }




.control-star-container {
  position: absolute;
  z-index: 10;
  margin-left: .7rem;
  margin-top: .5rem;
  font-size: 20px;
  opacity : 0; }

.control-status-container {
  top: 15px;
  right: 15px;
  position: absolute;
  z-index: 99; }

.control-status {
  width: 15px;
  height: 15px;
  background: rgb(150,150,150);
  border-radius: 50%; }

.control-status.online {
  background: $success; }

.control-status.offline {
  background: $danger; }

.control-tile-container {
  &.is-loading {
    position: relative;
    pointer-events: none;
    opacity: 0.8;
    background: black;
    &:after {
      @include loader;
      position: absolute;
      top: calc(50% - 2.5em);
      left: calc(50% - 2.5em);
      width: 5em;
      height: 5em;
      border-width: 0.25em; } } }



.control-star-container.starred, .control-star-container.starred i {
  opacity : 1; }

.control-star-container i {
  color: yellow;
  opacity : 0.7; }

.control-star-container .fa-star-o {
  opacity: 0.4; }


.control-star-container:not(.is-disabled):hover + .control-tile, .control-tile-container:not(.is-disabled):hover {
  transform: scale(1.02); }

.control-tile-container:hover .control-star-container {
  opacity: 1; }



h1.page-text {
  color: rgba(255,255,255,1);
  font-size: 35px;
  font-style: italic;
  font-weight: 400;
  text-align: center; }

h1.page-text.is-modal-text {
  font-size: 20px; }

.device {
  border-top: 2px solid $grey;
  transition: border-color 100ms ease; }

.device.is-offline {
    border-color: $danger; }
.device.is-online {
    border-color: $success; }


.device p.title {
  font-weight: 400; }

.device p:not(.title):first-child {
  margin-top: .5rem; }

.device .level {
  margin-top: 1rem; }

.device.card {
  background-position: 45% center;
  background-size: cover;
  background-repeat: no-repeat; }

.device .card-content {
  background: rgba(10,10,10,0.8); }

footer {
  background: none;
  padding: 0 6rem 6rem; }

footer hr {
  background: rgba(255,255,255,.1);
  margin: 6rem 30% 6rem 30%; }

footer p {
  font-size: 20px; }

.is-flex-1 {
  flex: 1; }

.Collapsible__trigger {
  &:not(.is-disabled) {
    cursor: pointer;
    .level-right {
      padding-right: 25px; } }
  &:after {
    font-family: 'Font Awesome 5 Free', monospace;
    content: "\f106";
    position: absolute;
    right: 20px;
    font-weight: 900;
    top: 23px;
    display: block;
    transition: transform 300ms; }
  &.is-open {
    &:after {
      transform: rotateZ(180deg); } }
  &.is-disabled {
   &:after {
     opacity : 0; } } }



// control panel elements
.control-panel h1.timer {
  font-size: 70px;
  text-align: center;
  padding: 20px 0 10px 0; }

.control-panel .status-blocks {
  display: flex; }


.control-panel label.checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  height: 25px;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.control-panel input.checkbox {
  position: absolute;
  opacity: 0;
  cursor: pointer; }

.control-panel .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: rgba(10,10,10,.7);
  transition: background-color 100ms ease; }

.control-panel label.checkbox:hover input ~ .checkmark {
  background-color: rgba(15,15,15,.7); }

.control-panel label.checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none; }


.control-panel label.checkbox input:checked ~ .checkmark:after {
  display: block; }

.control-panel label.checkbox .checkmark:after {
  left: 10px;
  top: 7px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg); }


.control-panel {
  .is-rounded {}
  .status-block {
    display: flex;
    flex-grow: 1;
    font-size: 20px;
    text-align: center;
    padding: 20px 20px 20px 20px;

    &.success {
      opacity: 0.3;
      background-color: rgba($success, .8);
      &.is-active {
        opacity: 1; } }
    &.danger {
      background-color: rgba($danger,.8);
      opacity: 0.3;
      &.is-active {
        opacity: 1; } } } }

.control-panel {
  margin-bottom: 100px;
  & .columns {
    display: flex !important; } }


.control-panel.is-rounded {
  border-radius: 40px; }


// Templating engine elements

.button.is-control {
    border: 0;
    margin-right: 0.5rem;
    margin-bottom: $control-button-margin;
    font-size: 16px;
    background-color: $control-button-background;
    transition: all 250ms ease;
    box-shadow: none;
    color: $white; }

.button.is-control:not(.is-active):hover {
  background-color: rgba($control-button-background, .7); }

.button.is-control:focus {
  box-shadow: none; }

.button.is-control:last-child {
  margin-bottom: 0; }

.button.is-control.is-icon {
  border-radius: 500px;
  font-size: 20px;
  width: $control-button-size;
  height: $control-button-size; }

.button.is-control.is-icon.has-label {
  font-size: 12px; }

.vertical-controller {
  width: $control-button-size;
  background-color: $control-button-background;
  border-radius: 1000px;
  margin: 0 auto; }

.vertical-controller button.is-icon {
  background-color: transparent; }

.vertical-controller .bottom-button button {
  margin-bottom: 0;
  margin-top: $control-button-margin; }

.vertical-controller .top-button button {
  margin-bottom: $control-button-margin !important; }

.vertical-controller button:hover {
  background-color: rgba($control-button-background, 0.3) !important; }







.button.is-control.is-active, .button.is-control:active {
  background-color: darken($info, 15%); }

.button.is-control.is-danger:focus, .button.is-control.is-danger:hover {
  background-color: lighten($danger, 5%); }




.button.is-control.is-active.is-warning, .button.is-control.is-active.is-warning:active {
  background-color: darken($warning, 15%); }


.button.is-control.is-warning:not(.is-active):hover {
  color: $white; }






.button.is-control.is-success {
  background-color: darken($success, 15%); }

.button.is-control.is-success:hover, .button.is-control.is-success:focus {
  background-color: darken($success, 5%); }


.template {
  & .template-title {
    font-weight: 400; } }
// Template editor
.template-editor {

  & .json-area {
    width: 100%;
    height: 100%;
    background-color: $input-background-color;
    padding: 10px;
    resize: none;
    border: 0;
    color: $white; }
  & .modal-card-body {
    padding: 0;
    & .template-editor-elements {
      background: rgba(255,255,255,.01);
      padding: 5px;
      padding-left: 20px;
      overflow-y: auto;
      height: 100%;
      min-height: 400px;

      & .editor-item-level:hover p {
        color: lighten(yellow, 30%); }

      & .editor-item-label {
        padding: 5px;
        margin-bottom: 0; }

      & .Collapsible__trigger:after {
        top: 6px; }

      & .Collapsible__trigger:not(.is-disabled) .level {
        padding-right: 20px; } } } }


.Toastify__toast-body {
  padding-left: 15px; }



.has-text-whiter {
  color: rgba($black, 0.6); }


.control-panel .level {
  margin-bottom: 1.5rem; }

.level h4 {
  margin-bottom: 0; }
